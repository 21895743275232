import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventType } from '@models/events/event-type.enum';
import { TranslateModule } from '@ngx-translate/core';
import { EventTemplateCardData } from '@models/design-templates/event-template-card-data.model';
import { EventTemplateService } from '@services/events/event-template.service';
import { MatDialog } from '@angular/material/dialog';
import { AppRoutes } from 'app/routes';
import { finalize } from 'rxjs';
import { EventService } from '@services/events/event.service';
import { NotificationService } from '@services/shared/notification.service';
import { Router } from '@angular/router';
import { GlobalEventStore } from '@services/stores/global-event.store';
import { User } from '@models/users/user.model';
import { ScreenWidthService } from '@services/shared/screen-width.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-design-template-card',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  providers: [
    EventTemplateService,
    EventService,
    NotificationService,
    ScreenWidthService,
  ],
  templateUrl: './design-template-card.component.html',
  styleUrl: './design-template-card.component.scss',
})
export class DesignTemplateCardComponent {
  @Input() template!: EventTemplateCardData;
  @Input() size?: number;
  @Input() selectMode = false;
  @Input() selected = false;

  @Input() createEventView = false;

  @Input() previewOnly = false;
  @Input() noCallback = false;
  @Input() allowActions = true;
  @Input() redirectOnPreview = false;

  @Input() displayShadow = false;
  @Input() eventColors: boolean = false;

  @Input() loggedUser?: User;

  isDesktop?: boolean;

  eventLoading = false;

  @Output() readonly templateChosen: EventEmitter<number> =
    new EventEmitter<number>();

  @Output() readonly createWithAIChosen: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  constructor(
    private eventTemplateService: EventTemplateService,
    private eventService: EventService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private router: Router,
    private globalEventStore: GlobalEventStore,
    private screenWidthService: ScreenWidthService,
    private bottomSheet: MatBottomSheet,
  ) {
    this.screenWidthService
      .isDesktop()
      .pipe(takeUntilDestroyed())
      .subscribe({
        next: (isDesktop: boolean) => {
          this.isDesktop = isDesktop;
        },
      });
  }

  chooseTemplate() {
    if (
      this.template.eventType !== EventType.WEDDINGPAGE &&
      !this.selectMode &&
      !this.canApplyProTemplate()
    ) {
      return this.openUnlockTemplate();
    }

    if (this.noCallback) {
      this.createEvent();
    } else {
      this.templateChosen.emit(this.template.id);
    }
  }

  async previewTemplate() {
    if (this.redirectOnPreview) {
      this.router.navigate([
        '/',
        AppRoutes.DesignTemplates.designTemplates,
        this.template.uri,
      ]);
    } else {
      const { TemplatePreviewOverlayPageComponent } = await import(
        '@modules/admin/design-templates/create-template/dialogs/template-preview-overlay-page/template-preview-overlay-page.component'
      );

      this.eventTemplateService
        .get(this.template.id)
        .subscribe((eventTemplate) => {
          const dialogRef = this.dialog.open(
            TemplatePreviewOverlayPageComponent,
            {
              width: '100%',
              height: '100%',
              maxWidth: '100%',
              panelClass: 'overlay-page-preview-dialog',
              data: {
                eventTemplate: eventTemplate,
              },
              autoFocus: false,
            },
          );
          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              if (result.useTemplate) {
                this.chooseTemplate();
              } else if (result.startWithAI) {
                this.createWithAIChosen.emit(true);
              }
            }
          });
        });
    }
  }

  createEvent() {
    this.eventLoading = true;
    this.eventService
      .create({
        eventTemplateId: this.template.id,
        name: this.template.eventName,
      })
      .pipe(
        finalize(() => {
          this.eventLoading = false;
        }),
      )
      .subscribe({
        next: (event) => {
          localStorage.setItem('eventUuid', event.uuid);
          this.globalEventStore.setEvent(event);
          if (this.router.url.startsWith(`/${AppRoutes.Events.events}`)) {
            this.router
              .navigate(['/', AppRoutes.Events.events, event.uri], {
                queryParams: { 'create-event-success': true, 'to-edit': true },
              })
              .then(() => {
                location.reload();
              });
          } else {
            this.router.navigate(['/', AppRoutes.Events.events, event.uri], {
              queryParams: { 'create-event-success': true, 'to-edit': true },
            });
          }
        },
        error: (err) => {
          if (err && err.message) {
            this.notificationService.error(err.message);
          }
        },
      });
  }

  async openUnlockTemplateDialog() {
    const { UnlockTemplateDialogComponent } = await import(
      '@modules/events/components/select-template/dialogs/unlock-template-dialog/unlock-template-dialog.component'
    );

    this.dialog.open(UnlockTemplateDialogComponent, {
      maxWidth: '795px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      panelClass: ['normal-dialog'],
      data: {
        eventTemplateName: this.template.eventName,
        loggedUser: this.loggedUser,
      },
      autoFocus: false,
    });
  }

  async openUnlockTemplateBottomSheet() {
    const { UnlockTemplateBottomSheetComponent } = await import(
      '@modules/events/components/select-template/dialogs/unlock-template-bottom-sheet/unlock-template-bottom-sheet.component'
    );

    this.bottomSheet.open(UnlockTemplateBottomSheetComponent, {
      panelClass: 'bottom-sheet-dialog',
      data: {
        eventTemplateName: this.template.eventName,
        loggedUser: this.loggedUser,
      },
    });
  }

  openUnlockTemplate() {
    if (this.isDesktop) {
      this.openUnlockTemplateDialog();
    } else {
      this.openUnlockTemplateBottomSheet();
    }
  }

  canApplyProTemplate() {
    return !Boolean(
      !this.loggedUser?.isPro() &&
        this.template?.eventType &&
        this.template?.eventType !== EventType.PARTYPAGE,
    );
  }

  protected readonly EventType = EventType;
}
